import React from 'react'
import GoogleMapReact from 'google-map-react'
import MapPin from '../images/icons/strange-music-logo-red.svg'

const Map = (props: {}) => {
    return(
        <GoogleMapReact
            bootstrapURLKeys={{
                key:'AIzaSyA2zl2Gk6bJ5NYnLFyYuRV5_r-cwkEDFxI'
            }}
            defaultCenter={{
                lat: 34.1022566,
                lng: -118.3406078
            }}
            defaultZoom={18}>
            <img src={MapPin} alt="Map pin" lat={34.1022566} lng={-118.3406078} width="50" height="50" />
        </GoogleMapReact>
    )
}

export default Map